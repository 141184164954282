import React, { useState } from 'react';
import styled from 'styled-components';
import { Hero, SEO, StaticCaseStudies, LikeWhatYouSee, WereSpecialists } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PrevArrow from 'images/shared/PrevArrow';

const StrategyPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        thumbnailImage: file(relativePath: { eq: "service-pages/strategy-video-thumbnail.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        alnylamImage: file(relativePath: { eq: "caseStudyImgs/alnylam-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        pfizerBMSImage: file(relativePath: { eq: "caseStudyImgs/pfizer-bms.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
      }
    `
  );

  const caseStudies = [
    {
      slug: '/our-work/alnylam-pharmaceuticals-disease-awareness-campaign/',
      image: data.alnylamImage,
      title: 'Disease Awareness Campaign,<br />HCP Engagement Campaign',
      company: 'Alnylam Pharmaceuticals'
    },
    {
      slug: '/our-work/social-media-campaign-strategy-pfizer-bristol-myers-squibb/',
      image: data.pfizerBMSImage,
      title: 'Social Media Campaign Strategy,<br />Content Strategy, Campaign X',
      company: 'Pfizer & Bristol Myers Squibb'
    }
  ];

  return (
    <>
      <SEO
        pageSpecificTitle="Omnichannel Strategies"
        pageSpecificDescription="Impactful Strategy Consultancy"
      />
      <Hero>
        <HeroContent>
          <Directions>
            <StyledLink to="/#services">
              <BackButton>
                <StyledPrevArrow />
              </BackButton>
            </StyledLink>
            <StyledLink to="/services/healthcare-digital-and-social-campaigns">
              <NextButton>
                <StyledNextArrow />
              </NextButton>
            </StyledLink>
          </Directions>
          <h1>Omnichannel Strategies</h1>
          <Text>
            <h2>
              The new standard for HCP
              <br />
              and Patient engagement
            </h2>
            <p>
              An Omnichannel marketing campaign for Healthcare requires selecting various channels
              and touchpoints to create a seamless and integrated experience for HCPs, patients, and
              other stakeholders.
            </p>
          </Text>
        </HeroContent>
      </Hero>
      <Section style={{ padding: 0 }}>
        <Video>
          <GatsbyImage image={getImage(data.thumbnailImage)} alt="Video thumbnail" />
          <video
            muted
            autoPlay
            loop
            playsInline
            id="Omnichannel Strategies"
            preload="auto"
            controls={false}>
            <source src="/omnichannel-strategies.mp4" type="video/mp4" />
          </video>
        </Video>
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Our Omnichannel Approach</ArticleTitle>
          <Column>
            <p>
              An Omnichannel approach to marketing will allow you to create an engaging brand experience for HCPs and Patients, while maximising your resources by unifying channels into one coherent system.
            </p>
            <p>
              Instead of multiple, disconnected channels, you will now have channels that reinforce your core message and encourage a deeper exploration of your brand story.
            </p>
          </Column>
          <Column>
            <p>
              Agency X can work with you on all aspects of your Omnichannel Marketing – from developing a strategic, research-based approach, to building content that fully engages your audience at each touchpoint, to the technology that delivers it.
            </p>
          </Column>
        </Article>
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Strategy Services at a Glance</ArticleTitle>
          <Column>
            <ul>
              <li>Scoping / Planning</li>
              <li>Strategy</li>
              <li>Creative Direction</li>
              <li>Storyboarding</li>
              <li>Campaign Analytics</li>
            </ul>
          </Column>
          <Column>
            <ul>
              <li>Market Research </li>
              <li>Brainstorm & Ideation</li>
              <li>Conceptualisation</li>
              <li>Media Buying</li>
              <li>Campaign Reporting</li>
            </ul>
          </Column>
        </Article>
      </Section>
      <Section>
        <WereSpecialists />
      </Section>
      <Section>
        <h2>Case Studies</h2>
        <p>
          Please find some of our recently produced events in our case study links below. All our
          projects are customised to the specific requirements of our clients and we will be
          delighted to share our experiences of best practice for your business event upon request.
        </p>
        <StaticCaseStudies caseStudies={caseStudies} />
      </Section>
      <LikeWhatYouSee margin />
    </>
  );
};

export const Head = () => (
  <link rel="preload" as="video" type="video/mp4" href="/omnichannel-strategies.mp4" />
);

const Directions = styled.div`
  align-items: center;
  display: flex;
  grid-column: span 2;
  justify-content: space-between;
  width: 100%;
`;

const BackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.125rem;
  transition: 150ms ease-in-out;
  &:hover {
    background-color: #000;
  }
  &:not(:hover) {
    background-color: var(--ax-gold);
  }
  &:active {
    background-color: #c1beac;
  }
`;

const NextButton = styled(BackButton)`
  svg {
    transform: rotate(180deg);
  }
`;

const StyledPrevArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledNextArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledLink = styled(AnchorLink)`
  display: block;
  grid-column: 1;
  margin-top: -0.75em;
  margin-bottom: 1rem;
  @media (min-width: 48em) {
    grid-column: 1/3;
  }
`;

const ArticleTitle = styled.h3`
  grid-column: span 1;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: left;
  @media (min-width: 48em) {
    grid-column: span 2;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 2;
  h3 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  ul {
    list-style: none;
    li {
      font-size: 1rem;
      line-height: 2em;
      list-style-type: none;
      text-decoration: underline;
      -webkit-text-decoration-color: var(--ax-gold); /* Safari */
      text-decoration-color: var(--ax-gold);
      width: auto;
    }
  }
  p {
    line-height: 1.3em;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    strong {
      padding-left: 2rem;
      position: relative;
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1.5em;
      &:before {
        content: '★';
        font-size: 1.25rem;
        color: var(--ax-gold);
        position: absolute;
        left: 0;
      }
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 300px;
  }
  @media (min-width: 48rem) {
    ul {
      li {
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: 62.5rem) {
    grid-column: span 1;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const Article = styled.article`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 3rem;
  margin: 2rem auto;
  margin-bottom: 0;
  width: 100%;
  @media (min-width: 62.5rem) {
    grid-row-gap: 2.5rem;
    grid-gap: 2.5rem;
    grid-template-columns: 1fr 1fr;
  }
`;

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 2rem 0;
  margin: 0 auto;
  > div {
    grid-column: 2/3;
  }
  > h2 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }

  @media (min-width: 48rem) {
    padding: 3rem;
    > h2 {
      font-size: 2rem;
      text-transform: uppercase;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
`;

const Video = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  video {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
  svg {
    height: 1.5rem;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  @media (min-width: 48em) {
    svg {
      align-self: flex-start;
    }
  }
  @media (min-width: 67.5em) {
    svg {
      height: 2.5rem;
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    grid-column: span 2;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 1.3em;
    margin-bottom: 1em;
    font-weight: 300;
    grid-column: span 2;
  }
  @media screen and (min-width: 48rem) {
    h1 {
      font-size: 2.75rem;
      grid-column: span 1;
    }
    h2 {
      font-size: 2rem;
      grid-column: span 1;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 1180px;
    margin: 3rem auto 3rem;
    align-items: start;
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 5rem;
    }
  }
`;

export default StrategyPage;
